
import Vue from "vue";
import Component from "vue-class-component";
import { DxButton } from "devextreme-vue/button";
import { Routes } from "@/router/routes";
import { customerModule } from "@/store/modules/customer";
import { CustomerActions } from "@/store/modules/customer/actions";
import { CustomerGetters } from "@/store/modules/customer/getters";
import CustomerEditor from "@/components/CustomerEditor/CustomerEditor.vue";
import ErrorBadge from "@/components/ErrorBadge/ErrorBadge.vue";
import { leaveConfirmation } from "@/services/leaveConfirmation";
import DynamicTitle from "@/components/DynamicTitle";
import FixedPanel from "@/components/FixedPanel";
import { TitleElement } from "@/components/DynamicTitle/TitleElement";

@Component({
    components: {
        DxButton,
        CustomerEditor,
        ErrorBadge,
        DynamicTitle,
        FixedPanel
    },
    computed: {
        ...customerModule.mapGetters({
            error: CustomerGetters.Error,
            isEdited: CustomerGetters.IsEdited
        })
    },
    methods: {
        ...customerModule.mapActions({
            loadCustomerById: CustomerActions.LoadCustomerById,
            saveCustomer: CustomerActions.SaveCustomer
        })
    }
})
export default class EditCustomer extends Vue {
    private readonly loadCustomerById!: (customerId: number) => Promise<void>;
    private readonly saveCustomer!: () => Promise<boolean>;

    protected readonly error!: string;
    protected readonly isEdited!: boolean;

    protected titleElements: TitleElement[] = [
        { name: "Overview", selected: false, link: Routes.Customers },
        { name: "Edit customer", selected: true }
    ];

    private leaveConfirmationEnabled = true;

    created(): void {
        if (this.customerId) {
            this.loadCustomerById(this.customerId);
        } else {
            this.$router.push(Routes.Customers);
        }

        window.onbeforeunload = () => this.isEdited;
    }

    beforeDestroy(): void {
        window.onbeforeunload = null;
    }

    get customerId(): number | null {
        const numberId = Number(this.$route.params.id);
        return !isNaN(numberId) ? numberId : null;
    }

    backButtonClick(): void {
        this.$router.push(Routes.Customers);
    }

    submit(): void {
        this.saveCustomer().then((res) => {
            if (res) {
                this.leaveConfirmationEnabled = false;
                this.$router.push(Routes.Customers);
            }
        });
    }

    beforeRouteLeave(
        _to: never,
        _from: never,
        next: () => void
    ): boolean | void {
        leaveConfirmation(this.isEdited && this.leaveConfirmationEnabled, next);
    }
}
