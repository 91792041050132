
import Vue from "vue";
import Component from "vue-class-component";
import { Customer } from "@/models/Customer";
import { customerModule } from "@/store/modules/customer";
import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxPager,
    DxButton,
    DxFilterRow,
    DxHeaderFilter,
    DxLookup,
    DxRemoteOperations
} from "devextreme-vue/data-grid";
import dxDataGrid, { ColumnButtonClickEvent } from "devextreme/ui/data_grid";
import { Routes } from "@/router/routes";
import { CustomerActions } from "@/store/modules/customer/actions";
import { Cell } from "devextreme/ui/pivot_grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { customerDataSourceEndpoint } from "@/api/customerApi";
import { CustomerMutations } from "@/store/modules/customer/mutations";
import { confirm } from "devextreme/ui/dialog";
import { CustomerGetters } from "@/store/modules/customer/getters";
import { User } from "@/models/User";

@Component({
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxPaging,
        DxPager,
        DxButton,
        DxFilterRow,
        DxHeaderFilter,
        DxLookup,
        DxRemoteOperations
    },
    methods: {
        ...customerModule.mapActions({
            deleteCustomer: CustomerActions.DeleteCustomer
        }),
        ...customerModule.mapMutations({
            setCustomers: CustomerMutations.SetCustomers
        })
    },
    computed: {
        ...customerModule.mapGetters({
            customers: CustomerGetters.Customers
        })
    }
})
export default class CustomerList extends Vue {
    private readonly deleteCustomer!: (customerId: number) => Promise<void>;
    private readonly setCustomers!: (customers: Customer[]) => void;

    private readonly customers!: Customer[];

    $refs!: Vue["$refs"] & {
        grid: DxDataGrid;
    };

    get grid(): dxDataGrid | undefined {
        return this.$refs.grid.instance;
    }

    protected dataSource = createStore({
        key: "customerId",
        loadUrl: customerDataSourceEndpoint,
        onLoaded: (customers: Customer[]) => this.setCustomers(customers)
    });

    editCustomer(e: ColumnButtonClickEvent<Customer>): void {
        const customerId = e.row?.data.customerId;
        if (customerId) {
            this.$router.push(Routes.EditCustomer + customerId);
        }
    }

    async removeCustomer(e: ColumnButtonClickEvent<Customer>): Promise<void> {
        const customerId = e.row?.data.customerId;
        if (customerId) {
            const confirmResult = await confirm(
                `Are you sure you want to delete ${e.row?.data.name}?`,
                "Confirm delete"
            );
            if (confirmResult) {
                await this.deleteCustomer(customerId);
                if (this.grid) {
                    this.grid.refresh();
                }
            }
        }
    }

    isCustomerText(cellInfo: Cell): string {
        return cellInfo.value ? "Customer" : "Prospect";
    }

    ikamFullName(rowData: Customer): string {
        if (rowData.ikam) {
            return `${rowData.ikam.firstName} ${rowData.ikam.lastName}`;
        }
        return "";
    }

    get ikamDataSource(): unknown[] {
        const ikamIds = [...new Set(this.customers.map((c) => c.ikamId))];
        const ikams = ikamIds
            .map((i) => this.customers.find((c) => c.ikamId === i)?.ikam)
            .filter((i) => i !== undefined) as User[];

        return ikams.map((i) => ({
            text: `${i.firstName} ${i.lastName}`,
            value: ["ikamId", "=", i.id]
        }));
    }
}
