
import Vue from "vue";
import Component from "vue-class-component";
import { DxTextBox } from "devextreme-vue/text-box";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxValidator, {
    DxRequiredRule,
    DxStringLengthRule
} from "devextreme-vue/validator";
import { customerModule } from "@/store/modules/customer";
import { CustomerGetters } from "@/store/modules/customer/getters";
import { Customer } from "@/models/Customer";
import { CustomerMutations } from "@/store/modules/customer/mutations";
import { LookupEntity } from "@/models/LookupEntity";
import ImageUploader from "@/components/ImageUploader";
import { userModule } from "@/store/modules/user";
import { UserActions } from "@/store/modules/user/actions";
import { UserGetters } from "@/store/modules/user/getters";

@Component({
    components: {
        DxTextBox,
        DxSelectBox,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        ImageUploader
    },
    computed: {
        ...customerModule.mapGetters({
            customer: CustomerGetters.SelectedCustomer
        }),
        ...userModule.mapGetters({
            IKAMs: UserGetters.LookupIkams
        })
    },
    methods: {
        ...customerModule.mapMutations({
            updateCustomer: CustomerMutations.UpdateSelectedCustomer,
            setIKAM: CustomerMutations.SetIKAMById
        }),
        ...userModule.mapActions({
            loadIKAMs: UserActions.LoadIKAMs
        })
    }
})
export default class CustomerEditor extends Vue {
    private readonly loadIKAMs!: () => Promise<void>;
    private readonly updateCustomer!: (fields: Partial<Customer>) => void;
    private readonly setIKAM!: (id: number) => void;

    protected readonly customer!: Customer | null;
    protected readonly IKAMs!: LookupEntity[];

    protected customerTypes = ["Customer", "Prospect"];

    created(): void {
        this.loadIKAMs();
    }

    get isL1NumberRequiredField(): boolean {
        return this.customer?.isCustomer ?? false;
    }

    get name(): string {
        return this.customer?.name ?? "";
    }
    set name(value: string) {
        this.updateCustomer({ name: value });
    }

    get type(): string {
        return this.customer?.isCustomer
            ? this.customerTypes[0]
            : this.customerTypes[1];
    }
    set type(value: string) {
        this.updateCustomer({ isCustomer: value === this.customerTypes[0] });
    }

    get selectedIKAM(): number | null {
        return this.customer?.ikamId || null;
    }
    set selectedIKAM(value: number | null) {
        if (value) {
            this.setIKAM(value);
        }
    }

    get L1Number(): string {
        return this.customer?.l1Number ?? "";
    }
    set L1Number(value: string) {
        this.updateCustomer({ l1Number: value });
    }

    imageSelected(image: File): void {
        this.updateCustomer({ logo: image });
    }

    get customerLogo(): string | null {
        return this.customer?.logoData ?? null;
    }
}
