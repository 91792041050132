
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { DxButton } from "devextreme-vue/button";

@Component({
    components: { DxButton }
})
export default class ImageUploader extends Vue {
    $refs!: {
        fileInput: HTMLInputElement;
    };

    @Prop({ required: false, type: String, default: null })
    protected image!: string;

    protected imageSource: unknown = null;

    valueChanged(e: FileList): void {
        const file = e[0];
        if (file) {
            if (!file.type.startsWith("image/")) {
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                this.imageSource = e.target?.result ?? null;
            };
            reader.readAsDataURL(file);
            this.$emit("image-selected", file);
        }
    }

    clickUploadButton(): void {
        this.$refs.fileInput.click();
    }
}
