import { render, staticRenderFns } from "./ErrorBadge.html?vue&type=template&id=18503fb2&scoped=true&"
import script from "./ErrorBadge.vue?vue&type=script&lang=ts&"
export * from "./ErrorBadge.vue?vue&type=script&lang=ts&"
import style0 from "./ErrorBadge.scss?vue&type=style&index=0&id=18503fb2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18503fb2",
  null
  
)

export default component.exports