
import Vue from "vue";
import Component from "vue-class-component";
import { customerModule } from "@/store/modules/customer";
import { Routes } from "@/router/routes";
import { CustomerGetters } from "@/store/modules/customer/getters";
import CustomerList from "@/components/CustomerList/CustomerList.vue";
import ErrorBadge from "@/components/ErrorBadge/ErrorBadge.vue";
import DynamicTitle from "@/components/DynamicTitle";
import BaseButton from "@/components/BaseButton";

@Component({
    components: { CustomerList, ErrorBadge, DynamicTitle, BaseButton },
    computed: {
        ...customerModule.mapGetters({
            error: CustomerGetters.Error
        })
    }
})
export default class Customers extends Vue {
    protected readonly error!: string;

    addNewCustomer(): void {
        this.$router.push(Routes.CreateCustomer);
    }
}
